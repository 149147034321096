import React, { useState, useEffect } from "react";
// import axios from "axios";
import SliderItem1 from '../data/projects/contempo/featured_slide.png';
import SliderItem2 from '../data/projects/house1/featured_slide.png';
import SliderItem3 from '../data/projects/meso-travel/featured_slide.png';
import SliderItem4 from '../data/projects/swift/featured_slide.png';
import SliderItem5 from '../data/projects/califano/featured_slide.png';
import SliderItem6 from '../data/projects/teraciel-properties/featured_slide.png';
import SliderItem7 from '../data/projects/terra-ecommerce/featured_slide.png';
import LeftArrow from "../assets/images/left-arrow.svg";
import RightArrow from "../assets/images/right-arrow.svg";
import { Link } from "react-router-dom";
import { Navigation, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import config from "../config";
import FeaturedMedia from "./featured/FeaturedMedia";

function FeaturedWorkSlider({ projects, title }) {
  const [isMobile, setIsMobile] = useState(false);
  const [projectsToShow, setProjectsToShow] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (isMobile) setProjectsToShow(projects.slice(0, 3));
    else setProjectsToShow(projects);
  }, [isMobile, projects]);

  if (!Array.isArray(projects)) {
    // console.error("Projects is not an array:", projects);
    return <div></div>;
  }
  const viewAllProjects = () => {
    setProjectsToShow(projects);
  };
  if (projectsToShow.length > 0) {
    return (
      <>
        <div className="featured_work_slider position-relative z-1 ptb-120">
          <h6 className="text-uppercase letter-spacing-5 font-12 text-center featured-title">
            {title}
          </h6>
          {isMobile ? (
            <div className="slider_wrapper position-relative">            
              <div>                
                  <div className='swiper-slide swiper-slide-active'>
                      <div className="common_work_slide  position-relative">
                          <img src={SliderItem1} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                          <div className="common_work_slide_text position-absolute w-100 h-100">
                              <div className="workbox_head">
                              <h2>toronto-based architecture firm</h2>
                              <p className="font-messina text-uppercase">WEB DESIGN + DEVELOPMENT</p> 
                              </div>
                              <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showdefault">
                              <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                              <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                              <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                              </svg>

                              <div className="work_slide_hover_content position-absolute">
                                  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showonhover">
                                  <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                  <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                                  <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                  </svg>
                              </div>
                              <div className="text-center d-flex justify-content-center project_link">
                                  <Link to="/project/contempo" className="text-white font-12 text-uppercase font-messina letter-spacing-5 underline_link">View Project</Link>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className='swiper-slide swiper-slide-active'>
                      <div className="common_work_slide  position-relative">
                          <img src={SliderItem2} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                          <div className="common_work_slide_text position-absolute w-100 h-100 h-100">
                              <div className="workbox_head">
                              <h2>miami-based luxury design firm</h2>
                              <p className="font-messina text-uppercase">WEB DESIGN + DEVELOPMENT</p> 
                              </div>
                              <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showdefault">
                              <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                              <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                              <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                              </svg>

                              <div className="work_slide_hover_content position-absolute">
                                  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showonhover">
                                  <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                  <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                                  <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                  </svg>
                              </div>
                              <div className="text-center d-flex justify-content-center project_link">
                                  <Link to="/project/house1" className="text-white font-12 text-uppercase font-messina letter-spacing-5 underline_link">View Project</Link>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className='swiper-slide swiper-slide-active'>
                      <div className="common_work_slide  position-relative">
                          <img src={SliderItem3} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                          <div className="common_work_slide_text position-absolute w-100 h-100 h-100">
                              <div className="workbox_head">
                              <h2>athens-based luxury yachting</h2>
                              <p className="font-messina text-uppercase">WEB DESIGN + DEVELOPMENT</p> 
                              </div>
                              <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showdefault">
                              <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                              <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                              <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                              </svg>

                              <div className="work_slide_hover_content position-absolute">
                                  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showonhover">
                                  <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                  <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                                  <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                  </svg>
                              </div>
                              <div className="text-center d-flex justify-content-center project_link">
                                  <Link to="/project/meso-travel" className="text-white font-12 text-uppercase font-messina letter-spacing-5 underline_link">View Project</Link>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className='swiper-slide swiper-slide-active'>
                      <div className="common_work_slide  position-relative">
                          <img src={SliderItem4} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                          <div className="common_work_slide_text position-absolute w-100 h-100 h-100">
                              <div className="workbox_head">
                              <h2>members-only dating app</h2>
                              <p className="font-messina text-uppercase">WEB DESIGN + BRAND DIRECTION</p> 
                              </div>
                              <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showdefault">
                              <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                              <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                              <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                              </svg>

                              <div className="work_slide_hover_content position-absolute">
                                  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showonhover">
                                  <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                  <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                                  <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                  </svg>
                              </div>
                              <div className="text-center d-flex justify-content-center project_link">
                                  <Link to="/project/swift" className="text-white font-12 text-uppercase font-messina letter-spacing-5 underline_link">View Project</Link>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className='swiper-slide swiper-slide-active'>
                      <div className="common_work_slide  position-relative">
                          <img src={SliderItem5} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                          <div className="common_work_slide_text position-absolute w-100 h-100 h-100">
                              <div className="workbox_head">
                              <h2>london-based event production</h2>
                              <p className="font-messina text-uppercase"></p> 
                              </div>
                              <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showdefault">
                              <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                              <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                              <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                              </svg>

                              <div className="work_slide_hover_content position-absolute">
                                  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showonhover">
                                  <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                  <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                                  <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                  </svg>
                              </div>
                              <div className="text-center d-flex justify-content-center project_link">
                                  <Link to="/project/califano" className="text-white font-12 text-uppercase font-messina letter-spacing-5 underline_link">View Project</Link>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className='swiper-slide swiper-slide-active'>
                      <div className="common_work_slide  position-relative">
                          <img src={SliderItem6} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                          <div className="common_work_slide_text position-absolute w-100 h-100 h-100">
                              <div className="workbox_head">
                              <h2>dubai-based property development</h2>
                              <p className="font-messina text-uppercase"></p> 
                              </div>
                              <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showdefault">
                              <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                              <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                              <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                              </svg>

                              <div className="work_slide_hover_content position-absolute">
                                  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showonhover">
                                  <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                  <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                                  <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                  </svg>
                              </div>
                              <div className="text-center d-flex justify-content-center project_link">
                                  <Link to="/project/teraciel-properties" className="text-white font-12 text-uppercase font-messina letter-spacing-5 underline_link">View Project</Link>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className='swiper-slide swiper-slide-active'>
                      <div className="common_work_slide  position-relative">
                          <img src={SliderItem7} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                          <div className="common_work_slide_text position-absolute w-100 h-100 h-100">
                              <div className="workbox_head">
                              <h2>dubai-based home ecommerce</h2>
                              <p className="font-messina text-uppercase"></p> 
                              </div>
                              <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showdefault">
                              <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                              <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                              <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                              </svg>

                              <div className="work_slide_hover_content position-absolute">
                                  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showonhover">
                                  <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                  <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                                  <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                  </svg>
                              </div>
                              <div className="text-center d-flex justify-content-center project_link">
                                  <Link to="/project/terra-ecommerce" className="text-white font-12 text-uppercase font-messina letter-spacing-5 underline_link">View Project</Link>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          ) : (
            <div className="slider_wrapper position-relative">
                <Swiper
                    // install Swiper modules
                    modules={[Navigation, A11y, Scrollbar]}
                    freeMode={true}
                    centeredSlides={true}
                    spaceBetween={70}
                    slidesPerView={3.8}
                    navigation={{ nextEl: ".arrow-right", prevEl: ".arrow-left" }}
                    scrollbar={{ draggable: true, el: '.swiper-custom-scrollbar' }}
                    loop={true}
                    breakpoints={{
                        320: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                        },
                        769: {
                            slidesPerView: 3,
                            spaceBetween: 15,
                        },
                        769: {
                            slidesPerView: 3,
                            spaceBetween: 15,
                        },
                        1280: {
                            slidesPerView: 3.5,
                            spaceBetween: 40,
                        },
                        1339: {
                            slidesPerView: 3,
                            spaceBetween: 78,
                        }
                    }}
                    
                    
                >
                    <SwiperSlide>
                        <div className="common_work_slide  position-relative">
                            <img src={SliderItem1} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                            <div className="common_work_slide_text position-absolute w-100 h-100">
                                <div className="workbox_head">
                                <h2>toronto-based architecture firm</h2>
                                <p className="font-messina text-uppercase">WEB DESIGN + DEVELOPMENT</p> 
                                </div>
                                <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showdefault">
                                <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                                <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                </svg>

                                <div className="work_slide_hover_content position-absolute">
                                    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showonhover">
                                    <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                    <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                                    <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                    </svg>
                                </div>
                                <div className="text-center d-flex justify-content-center project_link">
                                    <Link to="/project/contempo" className="text-white font-12 text-uppercase font-messina letter-spacing-5 underline_link">View Project</Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="common_work_slide  position-relative">
                            <img src={SliderItem2} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                            <div className="common_work_slide_text position-absolute w-100 h-100">
                                <div className="workbox_head">
                                <h2>miami-based luxury design firm</h2>
                                <p className="font-messina text-uppercase">WEB DESIGN + DEVELOPMENT</p> 
                                </div>
                                <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showdefault">
                                <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                                <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                </svg>
    
                                <div className="work_slide_hover_content position-absolute">
                                    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showonhover">
                                    <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                    <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                                    <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                    </svg>
                                </div>
                                <div className="text-center d-flex justify-content-center project_link">
                                    <Link to="/project/house1" className="text-white font-12 text-uppercase font-messina letter-spacing-5 underline_link">View Project</Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="common_work_slide  position-relative">
                            <img src={SliderItem3} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                            <div className="common_work_slide_text position-absolute w-100 h-100">
                                <div className="workbox_head">
                                <h2>athens-based luxury yachting</h2>
                                <p className="font-messina text-uppercase">WEB DESIGN + DEVELOPMENT</p> 
                                </div>
                                <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showdefault">
                                <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                                <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                </svg>
    
                                <div className="work_slide_hover_content position-absolute">
                                    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showonhover">
                                    <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                    <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                                    <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                    </svg>
                                </div>
                                <div className="text-center d-flex justify-content-center project_link">
                                    <Link to="/project/meso-travel" className="text-white font-12 text-uppercase font-messina letter-spacing-5 underline_link">View Project</Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="common_work_slide  position-relative">
                            <img src={SliderItem4} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                            <div className="common_work_slide_text position-absolute w-100 h-100">
                                <div className="workbox_head">
                                <h2>members-only dating app</h2>
                                <p className="font-messina text-uppercase">WEB DESIGN + BRAND DIRECTION</p> 
                                </div>
                                <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showdefault">
                                <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                                <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                </svg>
    
                                <div className="work_slide_hover_content position-absolute">
                                    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showonhover">
                                    <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                    <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                                    <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                    </svg>
                                </div>
                                <div className="text-center d-flex justify-content-center project_link">
                                    <Link to="/project/swift" className="text-white font-12 text-uppercase font-messina letter-spacing-5 underline_link">View Project</Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="common_work_slide  position-relative">
                            <img src={SliderItem5} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                            <div className="common_work_slide_text position-absolute w-100 h-100">
                                <div className="workbox_head">
                                <h2>london-based event production</h2>
                                <p className="font-messina text-uppercase"></p> 
                                </div>
                                <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showdefault">
                                <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                                <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                </svg>
    
                                <div className="work_slide_hover_content position-absolute">
                                    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showonhover">
                                    <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                    <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                                    <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                    </svg>
                                </div>
                                <div className="text-center d-flex justify-content-center project_link">
                                    <Link to="/project/califano" className="text-white font-12 text-uppercase font-messina letter-spacing-5 underline_link">View Project</Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="common_work_slide  position-relative">
                            <img src={SliderItem6} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                            <div className="common_work_slide_text position-absolute w-100 h-100">
                                <div className="workbox_head">
                                <h2>dubai-based property development</h2>
                                <p className="font-messina text-uppercase"></p> 
                                </div>
                                <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showdefault">
                                <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                                <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                </svg>
    
                                <div className="work_slide_hover_content position-absolute">
                                    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showonhover">
                                    <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                    <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                                    <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                    </svg>
                                </div>
                                <div className="text-center d-flex justify-content-center project_link">
                                    <Link to="/project/teraciel-properties" className="text-white font-12 text-uppercase font-messina letter-spacing-5 underline_link">View Project</Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="common_work_slide  position-relative">
                            <img src={SliderItem7} alt="Image" className="position-absolute top-0 start-0 h-100 w-100 object-fit-cover" />
                            <div className="common_work_slide_text position-absolute w-100 h-100">
                                <div className="workbox_head">
                                <h2>dubai-based home ecommerce</h2>
                                <p className="font-messina text-uppercase"></p> 
                                </div>
                                <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showdefault">
                                <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                                <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                </svg>
    
                                <div className="work_slide_hover_content position-absolute">
                                    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" className="showonhover">
                                    <circle cx="22.5" cy="22.5" r="22.25" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                    <circle cx="22.5" cy="22.5" r="11.25" fill="white" fill-opacity="0.1"/>
                                    <circle cx="22.5" cy="22.5" r="11" stroke="white" stroke-opacity="0.5" stroke-width="0.5"/>
                                    </svg>
                                </div>
                                <div className="text-center d-flex justify-content-center project_link">
                                    <Link to="/project/terra-ecommerce" className="text-white font-12 text-uppercase font-messina letter-spacing-5 underline_link">View Project</Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper> 
                
                <div className="slider_nav d-flex align-items-center justify-content-center nowrap">                
                    <button className="arrow-left arrow common_slider_arrow d-flex align-items-center justify-content-center"><img src={LeftArrow} alt="Image" /></button>
                    <div className="swiper-custom-scrollbar slider_scrollbar"></div>
                    <button className="arrow-right arrow common_slider_arrow d-flex align-items-center justify-content-center"><img src={RightArrow} alt="Image" /></button>
                </div>
            </div>
          )}
        </div>
      </>
    );
  } else {
    return <></>;
  }
}
export default FeaturedWorkSlider;
