import React from "react";
import { Link } from "react-router-dom";
import config from "../config";
import FeaturedMedia from "./featured/FeaturedMedia";

function MobileDisplay({ project }) {
  const {
    mobileImages,
    mobileTitle,
    mobileDescription,
  } = project;
  if (project) {
    return (
      <div className="bg-black text-white">
        <div className="seamless_booking_slider position-relative z-1 ptb-120">
          <div className="mobile_display_head text-center">
            <h6 className="text-uppercase m-0 text-base">MOBILE DISPLAY</h6>
            <div class="dotdivider text-center mtb15">
              <span></span>
            </div>
            <h2>{mobileTitle}</h2>
            <p className="font-messina">{mobileDescription}</p>
          </div>
          <div className="w-full d-flex justify-content-center mt-[75px]">
            <div className="mobile-display-list max-w-[1375px] w-full d-flex">
              <div className="w-full max-w-[240px] mobile-cover-none mobile-cover-side">
                <FeaturedMedia featuredImage={mobileImages[0]} />
              </div>
              <div className="w-full max-w-[240px] mobile-cover-none">
                <FeaturedMedia featuredImage={mobileImages[1]} />
              </div>
              <div className="w-full max-w-[240px] mobile-cover-none">
                <FeaturedMedia featuredImage={mobileImages[2]} />
              </div>
              <div className="w-full max-w-[240px] mobile-cover-none">
                <FeaturedMedia featuredImage={mobileImages[3]} />
              </div>
              <div className="w-full max-w-[240px] mobile-cover-none mobile-cover-side">
                <FeaturedMedia featuredImage={mobileImages[4]} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
export default MobileDisplay;
