import React, { useEffect, useRef, useState } from "react";
// import { Link } from 'react-router-dom';
import Gallery2 from "../assets/images/gallery-2.jpg";
import Gallery3 from "../assets/images/gallery-3.png";
import Video from "../assets/videos/single-projectslider-video.mp4";
import Video2 from "../assets/videos/single-projectslider-video2.mp4";
import { Link } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default function SingleProjectGallery() {
  const pinContainerRef = useRef(null);

  return (
    <>
      <div className="home_gallery_block overflow-hidden">
        {/* <div className="gallery-title-container mobile-version">
          <div className="gallery-title-content">
            <h3>full stack development meets experiential design</h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="5"
              viewBox="0 0 5 5"
              fill="none"
            >
              <circle cx="2.22222" cy="2.22222" r="2.22222" fill="#A2BB3C" />
            </svg>
            <ul>
              <li>app development</li>
              <li>custom data management solutions</li>
              <li>app integration meets branded ux</li>
            </ul>
          </div>
        </div> */}
        <div className="gallery-top-container">
          <Link to="/project/meso-travel" className="">
            <div className="gallery-content-1">
              <video autoPlay muted loop playsInline className="object-fit-cover">
                <source src={Video2} type="video/mp4" />
              </video>
              <div className="gallery-content-review-title">
                <h4>London-based luxury travel concierge</h4>
                <p>HOSPITALITY · BRAND DIRECTION · PHYSICAL DESIGN</p>
              </div>
            </div>
          </Link>
          <Link to="/project/house1" className="">
            <div className="gallery-content-2">
              <img src={Gallery2} alt="gallery-2" />
              <p
                className="gallery-review-title text-start"
                style={{ fontSize: "12px", textAlign: "left" }}
              >
                Tokyo-based coffee company
              </p>
            </div>
          </Link>
        </div>
        <div className="gallery-bottom-container">
          <Link to="/project/swift" className="">
            <div className="gallery-content-3 ">
              <img src={Gallery3} alt="gallery-3" />

              <p
                className="gallery-review-title messina-sans  w-full  m-0 "
                style={{ fontSize: "12px", textAlign: "left" }}
              >
                Members-only dating app - launching late 2024
              </p>
            </div>
          </Link>
          <Link to="/project/contempo" className="">
            <div className="gallery-content-4">
              <video autoPlay muted loop playsInline className="object-fit-cover">
                <source src={Video} type="video/mp4" />
              </video>
              <div className="gallery-content-review-title">
                <h4>Toronto-based architecture firm</h4>
                <p>HOSPITALITY · BRAND DIRECTION · PHYSICAL DESIGN</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}
